import React from 'react'
import { Button } from '../components/button'
import { TeaserEvents } from '../components/events/teaser-events'
import { Hero } from '../components/hero'
import { Highlight } from '../components/text/highlight'
import Layout from '../layouts'
import { SEO } from '../components/seo'
import { getOrganizedEventData } from '../utils/helpers'
import { graphql, PageProps } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { EurolandIframe } from '../components/euroland'

export default function IndexRoute(props: PageProps) {
  const home = props.data.home
  const teaserEvents = props.data.teaserEvents.edges
  //   const officeImage = getImage(props.data.officeImage)
  const info = props.data.info
  const eventsPage = props.data.eventsPage

  const sliderSettings = {
    dots: false,
    infinite: true,
    fade: true,
    speed: 3000,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 7000
  }
  

  return (
    <Layout location={props.location}>
      <div className="text-gray-700 bg-white dark:bg-gray-950 dark:text-gray-300">
        {home.hero && (
          <Hero
            heros={home.hero}
            slider
            randomize
            sliderSettings={sliderSettings}
          />
        )}
        <div className="wrapper">
          <div className="container relative mx-auto">
            <section className="flex flex-col py-10 md:py-20 md:flex-row">
              <div className="w-full h-full mb-16 md:w-1/3 md:mb-0">
                <TeaserEvents
                  isUpcomingEvent
                  needButton
                  title="Upcoming Events"
                  eventData={getOrganizedEventData(teaserEvents, 'future')}
                  noResults={eventsPage.noResults.noResults}
                />
              </div>
              <div className="w-full h-full mb-8 md:w-2/3 lg:mb-0">
                <div className="px:4 md:px-8">
                  <div className="text-left mb-4">
                    <Highlight className="dark:text-white">
                      Regulatory News
                    </Highlight>
                  </div>
                  <EurolandIframe
                    lightUrl="https://tools.eurolandir.com/tools/pressreleases/?companycode=us-umg&v=light&lang=en-GB"
                    darkUrl="https://tools.eurolandir.com/tools/pressreleases/?companycode=us-umg&v=dark&lang=en-GB"
                    adjustHeight
                  />

                  <Button
                    variant="primary"
                    outline={false}
                    size="medium"
                    href="/news"
                    label="View All"
                    className="mt-6"
                  />
                </div>
              </div>
            </section>
            <section className="flex flex-col py-10 border-t dark:border-gray-800 md:py-20 md:flex-row">
              <div className="order-2 w-full mt-16 md:w-1/2 md:pr-16 md:order-1 md:mt-0">
                <div className="about-text">
                  <Highlight className="text-primary">
                    {info.teaserSubtitle}
                  </Highlight>
                  <h2 className="pr-4 mt-4 text-xl font-bold leading-tight text-black md:text-2xl lg:text-3xl xl:text-4xl dark:text-white">
                    {info.teaserTitle}
                  </h2>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: info.summary.childMarkdownRemark.html
                    }}
                    className="block mx-auto my-6 prose dark:prose-dark"
                  />
                </div>
                <div className="block mt-8">
                  <Button
                    variant="primary"
                    outline={false}
                    size="medium"
                    href="/info"
                    label="Read More"
                  />
                </div>
              </div>
              <div className="order-1 w-full h-full md:w-1/2 md:order-2">
                <GatsbyImage
                  className="object-cover w-full aspect-w-3 aspect-h-2"
                  image={info.teaserImage.gatsbyImageData}
                  alt="office"
                />
                <span className="block mt-2 text-xs text-gray-600 dark:text-gray-500">
                  {info.teaserImage.description}
                </span>
              </div>
            </section>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const Head = () => (
  <SEO />
)

export const pageQuery = graphql`
  query {
    home: contentfulCustomPage(slug: { eq: "home" }) {
      title
      slug
      body {
        childMarkdownRemark {
          html
        }
      }
      hero {
        ...HeroFragment
      }
    }
    blogPage: contentfulCustomPage(slug: { eq: "news" }) {
      title
      noResults {
        noResults
      }
    }
    teaserEvents: allContentfulEvent(
      filter: { shown: { eq: true } }
      sort: { fields: [sticky, date], order: [DESC, ASC] }
    ) {
      edges {
        node {
          title
          location
          locationLinkText
          duration
          overrideDateBehavior
          date
          endDate
          startDateTime: date(formatString: "YYYYMMDDTHHmmssZ")
          endDateTime: endDate(formatString: "YYYYMMDDTHHmmssZ")
        }
      }
    }
    info: contentfulCustomPage(slug: { eq: "info" }) {
      title
      slug
      body {
        childrenMarkdownRemark {
          excerpt
        }
      }
      summary {
        childMarkdownRemark {
          html
        }
      }
      teaserTitle
      teaserSubtitle
      teaserImage {
        gatsbyImageData(width: 800)
        description
      }
    }
    eventsPage: contentfulCustomPage(slug: { eq: "events" }) {
      noResults {
        noResults
      }
    }
  }
`
